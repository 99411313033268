import React from "react";
import { useState, useEffect } from "react";
import Layout from './components/layout/Layout.js';
import ArticleNavigation from "./components/layout/ArticleNavigation";
import Spinner from "./pages/Spinner.js";
import  { HelmetProvider } from 'react-helmet-async';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import GetUrl from "./GetUrl";




function App({ t }) {
  
  const helmetContext = {};

  const [isLoading, setIsLoading] = useState(true);
  const [loadedNavs, setLoadedNavs] = useState([]);

  useEffect(() => {
    const ThemeURL = GetUrl();
    setIsLoading(true);
    fetch(ThemeURL+"/wp-json/wp/v2/option")
    .then(function (response) {
      return response.json();
    })
    .then(data => {
      //console.log(data);
      const navs = [];
      for (const key in data.routes_menu ){
        //console.log('/'+data[key].slug);
        let laRoute;
        let career = 'career';
        if(data.routes_menu[key].lang === 'FR'){
          laRoute = '/fr';
        }else{
          laRoute = '';
        }
        if(data.routes_menu[key].post_type === 'career' && data.routes_menu[key].lang === 'FR'){
            career = 'carriere';
        }
        //console.log(laRoute+'/'+data.routes_menu[key].slug);

        if(data.routes_menu[key].post_type === 'career'){
          const nav = {
            id: data.routes_menu[key].id,
            slug: laRoute+'/'+career+'/'+data.routes_menu[key].slug,
            title: data.routes_menu[key].title,
            post_type: data.routes_menu[key].post_type,
            template: data.routes_menu[key].template,
            lang: data.routes_menu[key].lang,
            translation_post: data.routes_menu[key].translation_post
          }
          navs.push(nav);
        }else if(data.routes_menu[key].post_type === 'service'){
          const nav = {
            id: data.routes_menu[key].id,
            slug: laRoute+'/service/'+data.routes_menu[key].slug,
            title: data.routes_menu[key].title,
            post_type: data.routes_menu[key].post_type,
            template: data.routes_menu[key].template,
            lang: data.routes_menu[key].lang,
            translation_post: data.routes_menu[key].translation_post
          }
          navs.push(nav);
        }else{
          const nav = {
            id: data.routes_menu[key].id,
            slug: laRoute+'/'+data.routes_menu[key].slug,
            title: data.routes_menu[key].title,
            post_type: data.routes_menu[key].post_type,
            template: data.routes_menu[key].template,
            lang: data.routes_menu[key].lang,
            translation_post: data.routes_menu[key].translation_post
          }
          navs.push(nav);
        }
        
      }

      
      setIsLoading(false);
      setLoadedNavs(navs);
      
    });
  }, []);

  if(isLoading){
    return (
      <Spinner />
    );
  }

  
  return (
    <div>
        <HelmetProvider context={helmetContext}>
        <Layout>
        <ArticleNavigation navs={loadedNavs} />
      </Layout>
      </HelmetProvider>
    </div>
    
  );
}

export default App;
