import AccordeonLogoImage from "./AccordeonLogoImage";
import classes from './LogoAccordeon.module.css';
function LogoAccordeon(props){

   
    
    if(props.logos){
    return(
        <div className={classes.ranger}>
        { 
        props.logos.map((acc, index) => (
            
            <AccordeonLogoImage 
            key={index}
            image={acc.logo}
            />
        ))}
    </div>
    );
    }
}
export default LogoAccordeon;