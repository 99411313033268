import classes from './RdDesignServices.module.css';
function RdDesignServices(props){

    return(
        <div className={classes.rdsrvices}>
            <div className={classes.container}>
            <div dangerouslySetInnerHTML={{__html: props.content}} />
            </div>
        </div>
    )

}
export default RdDesignServices;