import AllAccordeonItem from "./layout/AllAccordeonItem";
import $ from 'jquery';
import InViewPortClass from "../classes/InViewportClass";
$(document).ready(function(){
    
    $('body').on('click', '.title_acc', function(evt){
        evt.preventDefault();
        if( $(this).parent().find('.contenu').is(':hidden') ){
            $('.title_acc').removeClass('active');
            $(this).addClass('active'); 
            $('.conteneur').removeClass('active'); 
            $('.contenu').slideUp();
            $(this).parent().find('.contenu').slideDown();
            $(this).parent().parent().addClass('active');
        }else{ 
            $(this).parent().parent().removeClass('active'); 
            $(this).parent().find('.contenu').slideUp();
            $(this).removeClass('active'); 
        }
    });

});

function Accordeon(props){
    
    return (
    <InViewPortClass>
    <div className="accordeon">
        {props.content.map((acc, index) => (
            <AllAccordeonItem 
            key={index} 
            id={acc.id} 
            title={acc.title}
            content={acc.content}
            logos={acc.logos}
            />
        ))}
    </div>
    </InViewPortClass>
    );
}

export default Accordeon;