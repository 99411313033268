import AllRealisationItem from "./AllRealisationItem";
import classes from './AllRealisationList.module.css';
function AllRealisationList(props){
    return(
        <div className={classes.ranger}>
        {props.content.map((meetup, index) => (
            
            <AllRealisationItem 
            key={index} 
            id={index} 
            image={meetup.img_bg} 
            title={meetup.title}
            page={meetup.page}
            lang={props.lang}
            />
        ))}
    </div>
    )
}

export default AllRealisationList;