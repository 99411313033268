import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from 'react';
import classes from './FormCareer.module.css';
import GetUrl from '../GetUrl';
import axios from 'axios';
import $ from 'jquery';
const ThemeURL = GetUrl();

function FormCareer(props){

    
    const captchaRef = useRef(null);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const submitForm = async (e) =>{
        //e.preventDefault();
        //const token = captchaRef.current.getValue();
        //const yourValue = captchaRef.current.executeAsync();
        const token = await captchaRef.current.executeAsync();
        
        //captchaRef.current.reset();
        if(token.length > 0) {
            const formData = new FormData();
            
            let requiredField = 'All fields is required!';
            if(props.lang === 'FR'){
                requiredField = 'Tous les champs sont requis!';
            }
            if(name === '' || email === '' || phone === ''){
                $('#retourTexte').html(requiredField);
                return false;
            }else{
                $('#retourTexte').html('');
            }



            formData.append("language", props.lang);
            formData.append("job", props.job);
            formData.append("names", name);
            formData.append("message", message);
            formData.append("email", email);
            formData.append("phone", phone);
            formData.append("file", selectedFile);

            
            
            axios
                .post(ThemeURL+"/send-careers/", formData,  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }})
                .then(function(response) {
                if(response.data.data.success === 'send'){
                    document.getElementById("button").innerHTML = props.button;
                    document.getElementById("button").disabled = true;
                }
                
                })
                .catch(function(error) {
                //console.log(error);
                });
        }else{
            return false;
        }
        
    }
    const retour = function (){
        return false;
    }
    const filename = function (e){
        var fullPath = document.getElementById('upload').value;
        var fichier = document.getElementById('upload').files[0];
        var extension = document.getElementById('upload').files[0].type; 
        if(extension === 'image/jpeg' || extension === 'application/pdf' || extension === 'application/msword' || extension === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || extension === 'image/png'   ){
            $('#retourTexte').html('');
        }else{
            
            let retourFileText = '.jpg, .jpeg, .png, .doc, .docx, .pdf are allowed!';
            if(props.lang === 'FR'){
                retourFileText = '.jpg, .jpeg, .png, .doc, .docx, .pdf sont permis!';
            }
            $('#retourTexte').html(retourFileText);
            document.getElementById('upload').value = '';
            fullPath = document.getElementById('upload').value;
        }
        setSelectedFile(fichier);
        
        if (fullPath) {
            var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
            var filename = fullPath.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                filename = filename.substring(1);
            }
            document.getElementById('nomFichier').innerHTML = filename;
           
        }else{
            document.getElementById('nomFichier').innerHTML = '';
        }
        
    }
    console.log(props);
    return(
        
        <div id="form" className={classes.bg}>
            <ReCAPTCHA
                sitekey="6LcZLEkiAAAAANzFQVfTjk_-uLbuGtJojxQ-qVE0"
                size="invisible"
                ref={captchaRef}
            />
            
            <form  name="career" id="career" method='POST' encType="multipart/form-data" onSubmit={retour}>
                <div className={classes.ranger} >
                    <div className={classes.full} >
                        <input type="hidden" name="job" value={props.job} />
                        <input type="hidden" name="language" value={props.lang} />
                        <input type="text" name="name" id="name" className={classes.input} placeholder={props.name} value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className={classes.full} >
                        <input type="email" name="email" id="email" className={classes.input} placeholder={props.email} value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className={classes.full} >
                        <input type="tel" name="phone" id="phone" className={classes.input} placeholder={props.phone} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className={classes.col_sidebar}>
                        <label htmlFor="upload" className={classes.label}>{props.upload}</label>
                        <input type="file"  onChange={(e) => {filename(this)}} className={classes.none} name="upload" id="upload" />
                        <div id="nomFichier" className={classes.filename}></div>
                        <button className={classes.button} type="button" id="button" onClick={submitForm}>{props.send}</button>
                    </div>
                    <div className={classes.col_message}>
                        <textarea name="message" id="message" placeholder={props.message} className={classes.textarea} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        <span className={classes.retour} id="retourTexte"></span>
                    </div>
                </div>
                

            </form>
        </div>
    )
}

export default FormCareer;