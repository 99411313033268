import InViewPortClass from '../classes/InViewportClass';
import classes from './FourImagesServices.module.css';
import ImageForFour from './ImageForFour';



function FourImagesServices(props){
    return (
        <InViewPortClass>
        <div className={classes.ranger}>
            { props.images.map((image, index) => (
                <ImageForFour key={index} className={classes.menu} classe={image.large} source={image.image}/>
           ))
           }
        </div>
        </InViewPortClass>
    )
}
export default FourImagesServices;