import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import DefaultContent from './DefaultContent';
import Spinner from "../Spinner";
import classes from './DefaultAccueil.module.css';
import Banner from "../../components/Banner";
import AllRealisationList from "./AllRealisationsList";
import Accordeon from "../../components/Accordeon";
import SlidesPartenaires from "../../components/SlidesPartenaires";
import Footer from '../../components/layout/Footer';
import InViewPortClass from "../../classes/InViewportClass";
import GetUrl from "../../GetUrl";
import MainNavigation from "../../components/layout/MainNavigation";
import '../../App';
import EmailUS from "../../components/layout/EmailUS";






let mail;
let facebook;
let linkedin;
let phone;
let bring_the_future;

function DefaultAccueil(props){

 
  

  const [isLoading, setIsLoading] = useState(true);
  const [loadedContent, setLoadedContent] = useState([]);
  
  useEffect(() => {
    const ThemeURL = GetUrl();
    const postId = props.id;
    
    setIsLoading(true);
    fetch(ThemeURL+"/wp-json/wp/v2/pages/"+postId)
    .then(function (response) {
      return response.json();
    })
    .then(data => {

      
      
        const meetups = {
          id: data.id,
          title: data.title.rendered,
          content: data.content.rendered,
          description: data.content.rendered,
          slug: '/'+data.slug,
          imgBg: data.acf.img_bg.url,
          repeater: data.acf.accordeon,
          banner: data.acf.slides,
          services: data.acf.services,
          seoTitle: data.acf.seo_title,
          metaDescription: data.acf.meta_description,
          ogImage: data.acf.og_image
        }

      setIsLoading(false);
      setLoadedContent(meetups);
    });
    
  }, [props]); // add thew variable because it use
  
  
  
  

  const [isLoading3, setIsLoading3] = useState(true);
  const [loadedPartenaires, setLoadedPartenaires] = useState([]);
  const [loadedFooter, setLoadedFooter] = useState([]);
  useEffect(() => {
    const ThemeURL = GetUrl();
    setIsLoading3(true);
    fetch(ThemeURL+"/wp-json/wp/v2/option")
    .then(function (response) {
      return response.json();
    })
    .then(data => {

      const Partenaires = data.slides_partenaire;
      var footer = [];
      footer['col_1'] = data.col_1;
      footer['col_2'] = data.col_2;
      footer['col_3'] = data.col_3;
      footer['col_4'] = data.col_4;
      footer['col_5'] = data.col_5;

      mail = data.mail;
      facebook = data.facebook;
      linkedin = data.linkedin;
      phone = data.phone;

      if(props.lang === 'FR'){
        bring_the_future = data.forming_the_future_fr
      }else{
        bring_the_future = data.forming_the_future;
      }

      setIsLoading3(false);
      setLoadedPartenaires(Partenaires);
      setLoadedFooter(footer);
    });
  }, [props]);
  
  if(isLoading || isLoading3){
    return (
      <Spinner />
    );
  }
  let contact;
  if(props.lang === 'FR'){
    contact = 'Écrivez nous';
  }else{
    contact = 'Email us';
  }
  let copyText;
  if(props.lang === 'FR'){
    copyText = '© 2022 Tous droits réservés.';
  }else{
    copyText = '© 2022 All Rights Reserved.' ;
  }
  var MyimgBg =  loadedContent.imgBg;

    return(
      <div>
        <Helmet 
           defer={false}
           htmlAttributes={{"lang": props.lang}}>
          <title>{loadedContent.seoTitle}</title>
          <meta  name="description"  content={loadedContent.metaDescription} />;
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={loadedContent.ogImage.url}/>
          <meta
            name="twitter:title"
            content={loadedContent.seoTitle}
          />
          <meta
            name="twitter:description"
            content={loadedContent.metaDescription}
          />
          <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={loadedContent.seoTitle}
        />
        <meta
          property="og:description"
          content={loadedContent.metaDescription}
        />
        <meta
          property="og:image"
          content={loadedContent.ogImage.url}
        />
        </Helmet>
        
        <EmailUS text={contact} mail={mail} lang={props.lang} />
        <MainNavigation lang={props.lang} translation_post={props.translation_post} template={props.template}  />
        <InViewPortClass>
        <main className={classes.main} data-lang={props.lang}>
        <div className="banniere">
          <Banner banner={loadedContent.banner} lang={props.lang} />
        </div>
          
          
        <div className="">
          <AllRealisationList content={loadedContent.services} lang={props.lang} />
        </div>
        <div className={classes.texte}>
          <DefaultContent content={loadedContent.content} />
        </div>
        <InViewPortClass>
        <div className={classes.accordeon} style={{ 
          backgroundImage: 'url('+MyimgBg+')'
        }}>
          <Accordeon content={loadedContent.repeater} />
        </div>
        </InViewPortClass>
        <div className={classes.texte_experience}>
          <DefaultContent content={bring_the_future} />
        </div>
        <InViewPortClass>
        <div className="partenaires">
          <SlidesPartenaires content={loadedPartenaires} />
        </div>
        </InViewPortClass>
        <div className="footer">
          <Footer content={loadedFooter} text={copyText} mail={mail} facebook={facebook} linkedin={linkedin} phone={phone} lang={props.lang}/>
        </div>
        </main>
        </InViewPortClass>
      </div>
      
            
    )
}

export default DefaultAccueil;