import React, { useRef } from 'react';
import GetUrl from '../../GetUrl';
import axios from 'axios';

import classes from './EmailUS.module.css';
import $ from 'jquery';
import ReCAPTCHA from 'react-google-recaptcha';
const ThemeURL = GetUrl();

function EmailUS(props){
$(document).ready(function(){
    $('#open').click(function(evt){ 
        evt.preventDefault();
        if($('#form-email-us').is(":hidden")){
            $('#form-email-us').fadeIn();
        }else{
            $('#form-email-us').fadeOut();
        }
        
        
    });
    $('#form-email-us').click(function(evt){
        //evt.stopPropagation();
    });
    
});
const captchaRef = useRef(null);

const submitForm = async (e) =>{ 
    //e.preventDefault();
    //const token = captchaRef.current.getValue();
    //const yourValue = captchaRef.current.executeAsync();
    
    const token = await captchaRef.current.executeAsync();
    
    //captchaRef.current.reset();
    if(token.length > 0) {

        let requiredField = 'All fields is required!';
            if(props.lang === 'FR'){
                requiredField = 'Tous les champs sont requis!';
            }
        if(document.getElementById('name').value === '' || document.getElementById('email').value === '' || document.getElementById('company').value === '' || document.getElementById('tel').value === ''){
            $('#retourTexte').html(requiredField);
                return false;
        }else{
            $('#retourTexte').html('');
        }


        let button_texte = 'Thank you, we will contact you shortly!';
        if(props.lang === 'FR'){
            button_texte = 'Merci, nous vous contacterons sous peu!';
        }
        // With all properties
        let body = {
            language: document.getElementById('language').value,
            name: document.getElementById('name').value,
            email: document.getElementById('email').value,
            company: document.getElementById('company').value,
            tel: document.getElementById('tel').value,
            message: document.getElementById('message').value
        };
        axios
            .post(ThemeURL+"/send-home", body)
            .then(function(response) {
            if(response.data.data.success === 'send'){
                document.getElementById("button").innerHTML = button_texte;
                document.getElementById("button").disabled = true;
            }
            
            })
            .catch(function(error) {
            //console.log(error);
            });
    }else{
        return false;
    }
    
    
}
const retour = function (){
    return false;
}
    let name;
    let email;
    let company;
    let phone;
    let button;
    let message;
    if(props.lang === 'EN'){
        name = 'Name';
        email = 'Email';
        company = 'Company';
        phone = 'Tel';
        button = 'Send';
        message = "Message";
    }else{
        name= 'Nom';
        email = 'Courriel';
        company = 'Compagnie';
        phone= 'Tél';
        button= 'Envoyer';
        message = "Message";
    }

    return(
        <div id="fixed" className={classes.fixed}>
            <div id="form-email-us" className={classes.form}>
                <form name="email-us" id="email-us" onSubmit={retour}>
                <ReCAPTCHA
                    sitekey="6LcZLEkiAAAAANzFQVfTjk_-uLbuGtJojxQ-qVE0"
                    size="invisible"
                    ref={captchaRef}
                />
                    <input type="hidden" name="language" id="language" value={props.lang} maxLength="100"/>
                    <input type="text" name="name" id="name" placeholder={name} maxLength="100" />
                    <input type="email" name="email" id="email" placeholder={email} maxLength="100"/>
                    <input type="text" name="company" id="company" placeholder={company} maxLength="100" />
                    <input type="tel" name="tel" id="tel" placeholder={phone} maxLength="100" />
                    <textarea name="message" className={classes.textarea} id="message" placeholder={message}></textarea>
                    <button onClick={submitForm} type="button" id="button"><span dangerouslySetInnerHTML={{__html: button}} /></button>
                    <span className={classes.retour} id="retourTexte"></span>
                </form>
            </div>
            <div  id="open" className={classes.bouton}>
                <span dangerouslySetInnerHTML={{__html: props.text}} />
            </div>
        </div>
    )
}
export default EmailUS;