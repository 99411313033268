
import classes from "./MainNavigation.module.css";

import Logo from "./Logo";

import Spinner from "../../pages/Spinner";
import { useState, useEffect } from "react";
import SecLoadedNavMain from "./SecLoadedNavMain";
import $ from 'jquery';
import GetUrl from "../../GetUrl";
import { useLocation } from 'react-router-dom';

//import LngDetector from 'i18next-browser-languagedetector';

let currenturl;
let facebook;
let linkedin;
let phone;
let mail;

function MainNavigation(props) {
  
  $(document).ready(function(){
      $('header').addClass('active');
  });
  
  const [isLoading, setIsLoading] = useState(true);
  const [loadedNavs, setLoadedNavs] = useState([]);

  const [loadedTel, setLoadedTel] = useState([]);
  const [loadedFR, setLoadedFR] = useState([]);
  const [loadedEN, setLoadedEN] = useState([]);
  const [loadedOpposite, setLoadedOpposite] = useState([]);
  
  currenturl = useLocation().pathname;

  
  
  
  
  //console.log(location.pathname);
 
  useEffect(() => {
    const ThemeURL = GetUrl();
    setIsLoading(true);
    setLoadedOpposite(false);
    fetch(ThemeURL+"/wp-json/wp/v2/option")
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        const navs = [];
        
        let career;
        let oppositeCareer;
        for (const key in data.routes_menu) {
          if(data.routes_menu[key].post_type === 'career'){ 
            if(props.lang === 'FR'){
              career = 'carriere'; 
              oppositeCareer = 'career';
            }else{
              career = 'career'; 
              oppositeCareer = 'carriere';
            }
            
          }else{
            career = data.routes_menu[key].post_type;
            oppositeCareer = data.routes_menu[key].post_type;
          }
          //console.log(data.menu[key]);
          if(props.template !== 'home'){
            if(props.lang === 'EN' && ( currenturl === '/'+ data.routes_menu[key].slug || currenturl === '/'+data.routes_menu[key].post_type+'/' + data.routes_menu[key].slug ) && data.routes_menu[key].translation_post !== '#'){
              if(data.routes_menu[key].post_type !== 'page'){
                setLoadedOpposite( 'fr/'+oppositeCareer+'/'+data.routes_menu[key].translation_post);
              }else{
                setLoadedOpposite( 'fr/'+data.routes_menu[key].translation_post);
              }
              
              
            }else if(props.lang === 'FR' && ( currenturl === '/fr/'+ data.routes_menu[key].slug || currenturl === '/fr/' + career + '/' + data.routes_menu[key].slug ) && data.routes_menu[key].translation_post !== '#'){
              if(data.routes_menu[key].post_type !== 'page'){
                setLoadedOpposite( oppositeCareer +'/'+ data.routes_menu[key].translation_post );
              }else{
                setLoadedOpposite(  data.routes_menu[key].translation_post );
              }
              
            
            }else if(props.lang === 'EN' && ( currenturl === '/en/'+ data.routes_menu[key].slug || currenturl === '/en/' + career + '/' + data.routes_menu[key].slug ) && data.routes_menu[key].translation_post !== '#'){
              if(data.routes_menu[key].post_type !== 'page'){
                setLoadedOpposite( oppositeCareer +'/'+ data.routes_menu[key].translation_post );
              }else{
                setLoadedOpposite(  data.routes_menu[key].translation_post );
              }
              
            
            }
            
          }
        }
        let menuSelonLaLangue;
        if(props.lang === 'EN'){
          menuSelonLaLangue = data.menu;
        }else{
          menuSelonLaLangue = data.translate_menu;
        }
        for (const key in menuSelonLaLangue) {
          //console.log(data.menu[key]);
          let liens;
          if(props.lang === 'FR'){
              liens = '/fr/'+menuSelonLaLangue[key].slug;
          }else{
            liens = '/'+menuSelonLaLangue[key].slug;
          }
          
          

          const nav = {
            id: menuSelonLaLangue[key].id,
            slug: liens,
            title: menuSelonLaLangue[key].title,
            post_type: menuSelonLaLangue[key].post_type,
            template: menuSelonLaLangue[key].template,
            menu_item_parent: menuSelonLaLangue[key].menu_item_parent,
            has_child: menuSelonLaLangue[key].has_child,
            array_child: menuSelonLaLangue[key].array_child,
            menu_link: menuSelonLaLangue[key].url,
            translation_post: menuSelonLaLangue[key].translation_post,
            lang: menuSelonLaLangue[key].lang
          };
          navs.push(nav);
        }
        
        mail = data.mail;
        facebook = data.facebook;
        linkedin = data.linkedin;
        phone = data.phone;
        
        setLoadedNavs(navs);
        setLoadedTel(phone);
        setIsLoading(false);
        setLoadedEN(data.en);
        setLoadedFR(data.fr);
      });
  }, [props]);

  let Home;
  let oppositeHome;
  let oppositeTitle;
  if(props.lang === 'FR'){
    Home = loadedFR;
    oppositeHome = '/';
    oppositeTitle = 'EN';
  }else if(props.lang === 'EN'){
    Home = loadedEN;
    oppositeHome = '/fr/';
    oppositeTitle = 'FR';
  }
  if(loadedOpposite !== false){
    oppositeHome = '/'+loadedOpposite;
  }

  
  

  if (isLoading) {
    return <Spinner />;
  }
  
  return (
    <div>
      <div id="bouton-mobile">
        <div className="bar1" />
        <div className="bar2" />
        <div className="bar3" />
      </div>
      <header className={classes.header}>
        <div className={classes.container_header}>
          <Logo lang={props.lang} translation_post={props.translation_post} mail={mail} facebook={facebook} linkedin={linkedin} phone={phone} />
          <nav className={classes.ranger}>
            <ul className={classes.ranger_space_between}>
              <div>
              <a className={classes.first} href={'tel:+1'+loadedTel}>{loadedTel}</a>
              </div>
              <nav className={classes.menu} id="MainNav">
            <SecLoadedNavMain oppositeTitle={oppositeTitle} home={Home} currenturl={currenturl} oppositeHome={oppositeHome} navs={loadedNavs} tel={loadedTel} key="1" lang={props.lang} translation_post={props.translation_post} />
            </nav>

            </ul>
          </nav>
        </div>
      </header>
      <div id="menu-mobile">
          <SecLoadedNavMain oppositeTitle={oppositeTitle} home={Home} currenturl={currenturl} oppositeHome={oppositeHome} navs={loadedNavs} tel={loadedTel} key="1" lang={props.lang} translation_post={props.translation_post} />
      </div>
    </div>
  );

}

export default MainNavigation;
