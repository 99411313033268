//import Card from '../../components/ui/Card';

import InViewPortClass from '../../classes/InViewportClass';


function ContentAboutUs(props){
    
    return (
        <div className=''>
            <div>
              <div className=''>
                <div className=''>
                  <InViewPortClass>
                    <div dangerouslySetInnerHTML={{__html: props.content}} />
                  </InViewPortClass>
                </div>
              </div>
            </div>
        </div>
      );
}


export default ContentAboutUs;