import AvailablePosition from './AvailablePosition';
import classes from './AvailablePositions.module.css';

function AvailablePositions(props){
    const bouton = props.button;
    return(
        <div className={classes.container}>
            <div>
                <h2 className={classes.title}><span dangerouslySetInnerHTML={{__html: props.title }} /></h2>
            </div>
            {props.content.map((contenu,index) => (
                
                <AvailablePosition post_type={props.post_type} key={index} className={classes.position} content={contenu} button={bouton} lang={contenu.lang} />
            
             ))}
        </div>
    )
}
export default AvailablePositions;