import classes from "./AllRealisationItem.module.css";
import { Link } from 'react-router-dom';


function AllRealisationItem(props) {

  console.log(props.page);
  
  let link;
  if(props.page.post_type === 'service'){
    if(props.lang === 'FR'){
      link = '/fr/service/'+props.page.post_name;
    }else{
      link = '/service/'+props.page.post_name;
    }
  }else{
    if(props.lang === 'FR'){
      link = '/fr/'+props.page.post_name;
    }else{
      link = '/'+props.page.post_name;
    }
  }
  
  
  

  return (
    
    <div className={classes.item}>
       <Link to={link} className={classes.anchor}>
        <div className={classes.tuile}>
          <div className={classes.image}>
            <img className={classes.img} src={props.image.url} alt={props.image.alt} />
          </div>
          

          <div className={classes.title}>
            <h3><span dangerouslySetInnerHTML={{__html: props.title}} /></h3>
          </div>
          </div>
        </Link>
    </div>
   
    
  );
}

export default AllRealisationItem;
