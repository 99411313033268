import AllCertification from './AllCertification';
import classes from './Certifications.module.css';

function Certifications(props){
    return (
        <div className={classes.ranger}>
            {props.content.map((contenu,index) => (
                <AllCertification 
                key={index}
                text={contenu.text} 
                image={contenu.image}
                image_hover={contenu.image_hover}
                image_position={contenu.image_position}
                image_hover_position={contenu.image_hover_position}
                />
            ))}
        </div>
    )
}
export default Certifications;