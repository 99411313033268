import classes from './AllPartenairesItem.module.css';
function AllPartenairesItem(props){
    
    if(props.link && props.link !== ''){
        return(
            <div className={classes.col_slide}>
                <a href={props.link}>
                    <img className={classes.image} src={props.image} alt={props.alt} />
                </a>
            </div>
        )
    }else{
        return(
            <div className={classes.col_slide}>
                <img className={classes.image} src={props.image} alt={props.alt} />
            </div>
        )
    }
}
export default AllPartenairesItem;