
import classes from "./MainNavigation.module.css";


import Spinner from "../../pages/Spinner";
import { useState, useEffect } from "react";
import FooterLoadedNav from "./FooterLoadedNav";
import GetUrl from "../../GetUrl";
//import LngDetector from 'i18next-browser-languagedetector';

let ThemeURL = GetUrl();

function FooterNavigation(props) {
  
  
  
  const [isLoading, setIsLoading] = useState(true);
  const [loadedNavs, setLoadedNavs] = useState([]);

  
  useEffect(() => {
    setIsLoading(true);
    fetch(ThemeURL+"/wp-json/wp/v2/option")
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const navs = [];
        let menuChoice = data.menu_footer;
        if(props.lang === 'FR'){
            menuChoice = data.menu_footer_fr;
        }
        for (const key in menuChoice) {
          let lien;
          if(props.lang === 'FR'){
            if(menuChoice[key].slug === 'home' || menuChoice[key].slug === 'accueil'){
              lien = 'fr/';
            }else if(menuChoice[key].post_type === 'page'){
              lien = 'fr/'+ menuChoice[key].slug;
            }else if(menuChoice[key].post_type === 'service'){
              lien = 'fr/service/'+ menuChoice[key].slug;
            }else if(menuChoice[key].post_type === 'career'){
              lien = 'fr/carriere/'+ menuChoice[key].slug;
            }
          }else{
            if(menuChoice[key].slug === 'home' || menuChoice[key].slug === 'accueil'){
              lien = '';
            }else if(menuChoice[key].post_type === 'page'){
              lien = menuChoice[key].slug;
            }else if(menuChoice[key].post_type === 'service'){
              lien = 'service/'+ menuChoice[key].slug;
            }else if(menuChoice[key].post_type === 'career'){
              lien = 'career/'+ menuChoice[key].slug;
            }
          }
          

          const nav = {
            id: '100'+menuChoice[key].id,
            slug: "/" + lien,
            title: menuChoice[key].title,
            post_type: menuChoice[key].post_type,
            template: menuChoice[key].template,
          };
          navs.push(nav);
        }
        
        
        setIsLoading(false);
        setLoadedNavs(navs);
        
      });
  }, [props]);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <nav className={classes.ranger}>
        <ul>
          <FooterLoadedNav navs={loadedNavs} />
        </ul>
      </nav>
    </div>
  );
}

export default FooterNavigation;
