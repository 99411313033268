import classes from './Footer.module.css';
import FooterNavigation from './FooterNavigation';
import LogoFacebookHover from './images/facebook-f-w.svg';
import LogoFacebook from './images/facebook-f.svg';
import LogolinkedIn from './images/linkedin-in.svg';
import LogolinkedInHover from './images/linkedin-in-w.svg';
function Footer(props){
    return (
        <footer>
            <div className={classes.footer}>
                <div className={classes.container}>
                    <div className={classes.ranger}>
                        <div className={classes.colonne_1}  >
                            <div className={classes.inner} dangerouslySetInnerHTML={{__html: props.content.col_1}} />
                        </div>
                        <div className={classes.colonne_2} dangerouslySetInnerHTML={{__html: props.content.col_2}} />
                        <div className={classes.colonne_3} dangerouslySetInnerHTML={{__html: props.content.col_3}} />
                        <div className={classes.colonne_4}>
                        <div>
                            <div className={classes.quick} dangerouslySetInnerHTML={{__html: props.content.col_4}} />
                            <FooterNavigation lang={props.lang} />
                        </div>
                        </div>
                        <div className={classes.colonne_5}>
                            <div className={classes.social}>
                                <a target="_blank" rel="noreferrer" href={props.facebook}>
                                    <span className={classes.circle}>
                                        <img className={classes.hover} src={LogoFacebookHover} alt="facebook" />
                                        <img className={classes.normal} src={LogoFacebook} alt="facebook" />
                                    </span>
                                </a>
                                <a target="_blank" rel="noreferrer" href={props.linkedin}>
                                    <span className={classes.circle}>
                                    <img className={classes.hover} src={LogolinkedInHover} alt="linkedin" />
                                        <img className={classes.normal} src={LogolinkedIn} alt="linkedin" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className={classes.copy}>
                <div className={classes.container}>
                    <p><span  dangerouslySetInnerHTML={{__html: props.text}} /></p>
                </div>
            </div>
        </footer>
        
    )
}
export default Footer;