import React from 'react';
import classes from './AllAccordeonItem.module.css';
import LogoAccordeon from './LogoAccordeon';
  

function AllAccordeonItem(props)  {
    
   
    
    return(
        <div className={classes.ranger_accordeon + ' conteneur'}>
            <div className={classes.accordeon_container}>
                <h3 className='title_acc' ><span dangerouslySetInnerHTML={{__html: props.title}}></span></h3>
                <div className='contenu'>
                    <div className={classes.content} dangerouslySetInnerHTML={{__html: props.content}} />
                    <LogoAccordeon logos={props.logos} />
                </div>
            </div>
        </div>
    )

}
export default AllAccordeonItem;