import classes from './HeaderAboutUs.module.css';
function HeaderAboutUs(props){

    let MyimgBg = props.image.url;

    return(
        <div className={classes.bg} style={{ backgroundImage: 'url('+MyimgBg+')'}} >
            <div className={classes.container}>
                <div className={classes.titre}>
                    <h1><span dangerouslySetInnerHTML={{__html: props.title }}></span></h1>
                </div>
            </div>
        </div>
    )
}
export default HeaderAboutUs;