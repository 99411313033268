import { Route, Routes } from 'react-router-dom';
import Article from "../../pages/Article";


function ArticleNavigation(props){

    

    return (
        <Routes>
            <Route key="1" path="/" element={<Article id="14" template="home" post_type="page" lang='EN' translation_post='331'  />} />
            <Route key="1" path="/fr" element={<Article id="331" template="home" post_type="page" lang='FR' translation_post='14' />} />
            {props.navs.map((nav, index) => ( 
                <Route key={index} path={nav.slug} element={<Article post_type={nav.post_type} template={nav.template} id={nav.id} lang={nav.lang} translation_post={nav.translation_post}  />} />
            ))};
        </Routes>
        
    );
}

export default ArticleNavigation;