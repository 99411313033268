
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

var classe = '';
class InViewPort extends Component {
  state = {
    visibility: false
  }
  
  render() { 
    classe = this.state.visibility ? 'active' : '';
    return (
      <VisibilitySensor
      partialVisibility
      /*
      offset={ {
        direction: 'top',
        value: 20
      } }
      */

        onChange={(isVisible) => {
          this.setState({visibility: isVisible});
          
        }}
      >
        <div className={classe}
          style={{
            opacity: this.state.visibility ? 1 : 0.25,
            transition: 'opacity 500ms linear'
          }}
        >
            {this.props.children}
        </div>
      </VisibilitySensor>
    );
  }
}

export default InViewPort;