import classes from './AboutTeam.module.css';
import AllTeamItem from './AllTeamItem';
function AboutTeam(props){

    
    return(
        <div className={classes.team}>
            <div className={classes.container}>
                <div className={classes.intro}>
                    <div dangerouslySetInnerHTML={{__html: props.intro}} />
                </div>
                <div className={classes.teams}>
                {props.team.map((team, index) => (
                        <AllTeamItem 
                        key={index}
                        name={team.name} 
                        job={team.job}
                        image={team.picture}
                        phone={team.phone}
                        linkedin={team.linkedin}
                        mail={team.mail}
                        />
                    ))}
                </div>

            </div>
        </div>
    )
}
export default AboutTeam;