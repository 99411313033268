
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';


var classe = 'action_animated';
class InViewPortClass extends Component {
  state = {
    visibility: false
  }
  
  render() { 
    classe = this.state.visibility ? 'animated action_animated' : 'action_animated';
    
   
    return (
      <VisibilitySensor
      partialVisibility={true}
      
      minTopValue={50}
      

        onChange={(isVisible) => {
          this.setState({visibility: isVisible});
          
        }}
      >
        <div className={classe} >
            {this.props.children}
        </div>
      </VisibilitySensor>
    );
  }
}

export default InViewPortClass;