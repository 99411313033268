import classes from './CareerInfo.module.css';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton
  } from "react-share";
import facebookIcon from './layout/images/facebook-f.svg';
import twitterIcon from './layout/images/twitter.svg';
import linkedinIcon from './layout/images/linkedin-in.svg';
import envelopeIcon from './layout/images/envelope.svg';
import FormCareer from './FormCareer';
function CareerInfo(props){
    
    console.log(props);
  
    let share = 'Share';
    if(props.lang === 'FR'){
        share = 'Partager';
    }

    return (
        <div className={classes.container}>
            <div className={classes.ranger}>
                <div className={classes.title}>
                    <h2><span dangerouslySetInnerHTML={{__html: props.content.job }} /></h2>
                </div>
                <div className={classes.job_description}>
                    <div dangerouslySetInnerHTML={{__html: props.content.job_description }} />
                </div>  
            </div>
            <div className={classes.ranger_info}>
                <div className={classes.sidebar}>
                    <div dangerouslySetInnerHTML={{__html: props.content.sidebar }} />
                    <hr />
                    <div className={classes.ranger_reseaux}>
                    <div className={classes.share} dangerouslySetInnerHTML={{__html: share }} />
                    <div>
                    <FacebookShareButton
                        url={window.location.href}
                        quote={props.seoTitle}
                        description={props.metaDescription}
                        className="share-button"
                    >
                        <img src={facebookIcon} alt="facebook" className={classes.icone} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={window.location.href}
                        quote={props.seoTitle}
                        description={props.metaDescription}
                        className="share-button"
                    >
                        <img src={twitterIcon} alt="twitter" className={classes.icone} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        url={window.location.href}
                        title={props.seoTitle}
                        description={props.metaDescription}
                        className="share-button"
                    >
                        <img src={linkedinIcon} alt="LinkedIn" className={classes.icone} />
                    </LinkedinShareButton>
                    <EmailShareButton
                         url={window.location.href}
                        subject={props.seoTitle}
                        body={''}
                        className="share-button"
                    >
                    <img src={envelopeIcon} alt="email" className={classes.icone} />
                    </EmailShareButton>
                    </div>
                    </div>
                </div>
                <div className={classes.additionnal_info}>
                    <div dangerouslySetInnerHTML={{__html: props.content.additional_info }} />
                    <FormCareer lang={props.lang} name={props.content.name} upload={props.content.upload} email={props.content.email} phone={props.content.phone} message={props.content.message} send={props.content.send} job={props.content.title} button={props.content.success} />
                </div>
            </div>
        </div>
       
    )
}

export default CareerInfo;