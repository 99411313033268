import BannerItems from './BannerItems';



function Banner(props){
  

  return (
    <div>
      <BannerItems banner={props.banner} lang={props.lang} />
    </div>
     
   
  );

}
export default Banner;