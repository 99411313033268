import { useState, useEffect } from "react";
//import BannerElement from "./BannerElement";
import Spinner from "../pages/Spinner";
import BannerElement from "./BannerElement";
import Slider from "react-slick";


function BannerItems(props){
    const [isLoading, setIsLoading] = useState(true);
    const [loadedSlides, setLoadedSlides] = useState([]);
    
    useEffect(() => {
        setIsLoading(true);
        
        let Slider = [];
         //alert(data.length);
        for (let i=0; i < props.banner.length; i++){ //alert('1');
            //console.log(props.banner.Banner[i]);
            
            Slider.push( 
            <BannerElement key={i} image={props.banner[i].image.url}
                texte={props.banner[i].texte} 
                link={props.banner[i].link}
                
                />);
        }
        setIsLoading(false);
        setLoadedSlides(Slider);
        
    
    }, [props.banner]);
      if(isLoading){
        return (
          <Spinner />
        );
      }
      
      let buffer = []
      loadedSlides.map(function(val){
        return buffer.push(val);
      });

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover:false,
        
      }
    // And return the buffer for display inside the render() function
    return (
        <Slider {...settings}>
          {buffer}
        </Slider>
      
        
    );
}

export default BannerItems;
