import classes from './Video.module.css';
function Video(props){
    console.log(props.video.length);
    if (props.video.length > 0){
        return(
            <div className={classes.col_video}>
                <div className={classes.div} dangerouslySetInnerHTML={{__html: props.video }} />
                <p><span dangerouslySetInnerHTML={{__html: props.title }} /></p>
            </div>
        )
    }else{
        return(
            <div  className={classes.col}>
                <img className={classes.img} src={props.image.url} alt={props.image.alt} />
                <p><span dangerouslySetInnerHTML={{__html: props.title }} /></p>
            </div>
        )
    }
    
}
export default Video;