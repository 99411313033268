
//import { Link } from 'react-router-dom';
//import { buildTimeValue } from '@testing-library/user-event/dist/utils';
import { Link } from 'react-router-dom';
import classes from './SecLoadedNavMain.module.css';

function SecLoadedNavMain(props){

    //console.log(props.navs);

    
    
    let Langue;

    

    if(props.lang === 'FR'){
        Langue = '/fr'
    }else{
        Langue = '';
    }

    let Menus = [];

    for ( let i = 0; i < props.navs.length; i++){ 
        Menus[i] = [];
        //if(props.navs[i].menu_item_parent === '0'){
            //Menus[i] = (<MainMenu slug={props.navs[i].slug} title={props.navs[i].title} hasChild="false" menu_item_parent={props.navs[i].menu_item_parent} array_child={props.navs[i].array_child} />);
            Menus[i]['id']= '99999'+props.navs[i].id;
            Menus[i]['slug']= props.navs[i].slug;
            Menus[i]['title']= props.navs[i].title;
            Menus[i]['array_child']= props.navs[i].array_child;
            if(props.navs[i].menu_link === '#'){
                Menus[i]['slug'] = '#';
            }
            Menus[i]['lang']= props.navs[i].lang;
            Menus[i]['translation_post']= props.navs[i].translation_post;
            
            
    }
    
    return(
        <ul className={classes.ranger_space_between}>
        
        
           { Menus.map((nav, index) => (
            <li className={classes.menu_item} key={index}>
                {nav.slug !== '#' ? (
                    <Link  className={classes.menu} key={nav.id}  to={nav.slug}><span dangerouslySetInnerHTML={{__html: nav.title }} /></Link>
                    ):(
                        <span className='sub' dangerouslySetInnerHTML={{__html: nav.title }} />
                    )
                }
                    <ul className={classes.absolute}>
                     { nav.array_child.map((nav2) => ( 
                        <li className={classes.sub_item} key={'777'+nav2.id}>
                                <Link className={classes.menu} key={'999'+nav2.id} to={ Langue+ nav2.seperator+ nav2.post_type.replace('page', '')+'/' + nav2.slug}><span dangerouslySetInnerHTML={{__html: nav2.title }} /></Link>
                        </li>
                    ))
                    }
                    
                    </ul>
            </li>
           ))
           }
            <li className={classes.lang}>
                <Link to={props.oppositeHome} key="1234">{props.oppositeTitle}</Link>
            </li>        
        </ul>
       
    )
    
}

export default SecLoadedNavMain;