import AllPartenairesItem from "./AllPartenairesItem";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import Spinner from "../pages/Spinner";
import classes from './SlidesPartenaires.module.css';
function SlidesPartenaires(props){

    const [isLoading, setIsLoading] = useState(true);
    const [loadedSlides, setLoadedSlides] = useState([]);
    
    useEffect(() => {
        setIsLoading(true);
        
        let Slider = [];
         //alert(data.length);
        for (let i=0; i < props.content.length; i++){ //alert('1');
            //console.log(props.banner.Banner[i]);
            
            Slider.push( 
            <AllPartenairesItem key={i} image={props.content[i].image.url}
                alt={props.content[i].alt} 
                link={props.content[i].link}
            />);
        }
        for (let i=0; i < props.content.length; i++){ //alert('1');
          //console.log(props.banner.Banner[i]);
          
          Slider.push( 
          <AllPartenairesItem key={i} image={props.content[i].image.url}
              alt={props.content[i].alt} 
              link={props.content[i].link}
          />);
      }
      for (let i=0; i < props.content.length; i++){ //alert('1');
        //console.log(props.banner.Banner[i]);
        
        Slider.push( 
        <AllPartenairesItem key={i} image={props.content[i].image.url}
            alt={props.content[i].alt} 
            link={props.content[i].link}
        />);
    }
    for (let i=0; i < props.content.length; i++){ //alert('1');
      //console.log(props.banner.Banner[i]);
      
      Slider.push( 
      <AllPartenairesItem key={i} image={props.content[i].image.url}
          alt={props.content[i].alt} 
          link={props.content[i].link}
      />);
  }
        
        setIsLoading(false);
        setLoadedSlides(Slider);
        
    
    }, [props.content]);
      if(isLoading){
        return (
          <Spinner />
        );
      }
      
      let buffer = []
      loadedSlides.map(function(val){
        return buffer.push(val);
      });

      const settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 6,
        slidesToScroll: 1,
        fade: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover:false,
        variableWidth: true,
        cssEase: 'linear',
        centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              autoplaySpeed: 5000,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    // And return the buffer for display inside the render() function
    return (
        <div className={classes.slide_partenaires}>
            <Slider {...settings} >
            {buffer}
            </Slider>
        </div>
        
    );

    
}
export default SlidesPartenaires;