import { useState, useEffect } from "react";
import DefaultContent from './DefaultContent';
import Spinner from "../Spinner";

function DefaultTemplate(props){

const [isLoading, setIsLoading] = useState(true);
  const [loadedContent, setLoadedContent] = useState([]);
  
  useEffect(() => {
    const postId = props.id;
    setIsLoading(true);
    fetch("http://localhost:8888/mars/wp-json/wp/v2/pages/"+postId)
    .then(function (response) {
      return response.json();
    })
    .then(data => {

      console.log(data);
        const meetups = {
          id: data.id,
          title: data.title.rendered,
          content: data.content.rendered,
          description: data.content.rendered,
          slug: '/'+data.slug,
          texte: data.acf.texte.rendered
        }

      setIsLoading(false);
      setLoadedContent(meetups);
    });
  }, [props]); // add thew variable because it use

  

  if(isLoading){
    return (
      <Spinner />
    );
  }


    console.log('id'+props.id)
    return(
       
        <DefaultContent content={loadedContent} />
            
    )
}

export default DefaultTemplate;