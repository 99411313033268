import classes from './FourImages.module.css';

function FourImages(props){
    return (
        <div className={classes.ranger}>
            <div className={classes.col}>
                <img src={props.img_1.url} alt={props.img_1.alt} />
            </div>
            <div className={classes.col}>
                <img src={props.img_2.url} alt={props.img_2.alt} />
            </div>
            <div className={classes.col}>
                <img src={props.img_3.url} alt={props.img_3.alt} />
            </div>
            <div className={classes.col}>
                <img src={props.img_4.url} alt={props.img_4.alt} />
            </div>
        </div>
    )
}
export default FourImages;