import DefaultTemplate from './Article/DefaultTemplate';
import DefaultAccueil from './Article/DefaultAccueil';
import TemplateAboutUs from './Article/TemplateAboutUs';
import TemplateContacts from './Article/TemplateContact';
import TemplateCertification from './Article/TemplateCertification';
import TemplateRDdesign from './Article/TemplateRDdesign';
import TemplateServiceProcess from './Article/TemplateServiceProcess';
import TemplateCareers from './Article/TemplateCareers';
import TemplateCareer from './Article/TemplateCareer';

function Article(props){
   
    
    if (props.template === 'home') {
        return(
            <div>
                <DefaultAccueil id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} />
            </div>
            
        )
    }else if(props.template === "templates/about-us.php") {
        return (
            <div>
                <TemplateAboutUs id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} />
            </div>
            
        )
    }else if(props.template === 'templates/contact.php') {
        return (
            <div>
                <TemplateContacts id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template}/>
            </div>
            
        )
    }else if(props.template === 'templates/certification.php') {
        return (
            <div>
                <TemplateCertification id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} />
            </div>
            
        )
    }else if(props.template === 'templates/r&ddesign.php') {
        return (
            <div>
                <TemplateRDdesign id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} />
            </div>
            
        )
    }else if(props.template === 'templates/service_process.php') {
        return (
            <div>
                <TemplateServiceProcess id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} post_type={props.post_type} />
            </div>
            
        )
    }else if(props.template === 'templates/careers.php') {
        return (
            <div>
                <TemplateCareers id={props.id} translation_post={props.translation_post} lang={props.lang} template={props.template} />
            </div>
            
        )
    }else if(props.post_type === 'career') {
        return (
            <div>
                <TemplateCareer id={props.id} translation_post={props.translation_post} lang={props.lang} />
            </div>
            
        )
    } else {
        return(
            <div>
                <DefaultTemplate id={props.id} translation_post={props.translation_post} lang={props.lang} /> 
            </div>
        )
    }
    
}

export default Article;