import classes from './ImageForFour.module.css';

function ImageForFour(props){


    return (
        <img src={props.source.url} className={classes.img} classe={props.classe} alt={props.source.alt}/>
    )

}


export default ImageForFour;