import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from 'react';
import classes from './FormContact.module.css';
import GetUrl from '../GetUrl';
import axios from 'axios';
import $ from 'jquery';
const ThemeURL = GetUrl();

function FormContact(props){

    const [loadedCity, setloadedCity] = useState("");

        $('body').on('click','#city-richmond', function(){ 
            $('.text_city').removeClass('active');
            $(this).addClass('active');
            $('.city').removeAttr('checked');
            $('#richmond').attr('checked', 'checked');
            $('#p-richmond').show();
            $('#p-laval').hide();
            setloadedCity($(this).html());
        });
        $('body').on('click','#city-laval', function(){ 
            $('.text_city').removeClass('active');
            $(this).addClass('active');
            $('.city').removeAttr('checked');
            $('#laval').attr('checked', 'checked');
            $('#p-richmond').hide();
            $('#p-laval').show();
            setloadedCity($(this).html());
        });
    
    

    const captchaRef = useRef(null);
    
    
    const submitForm = async (e) =>{
        //e.preventDefault();
        //const token = captchaRef.current.getValue();
        //const yourValue = captchaRef.current.executeAsync();
        const token = await captchaRef.current.executeAsync();
        
        //captchaRef.current.reset();
        if(token.length > 0) {
            
            let messageCity = 'Please select a city!';
            let requiredField = 'All fields is required!';
            if(props.lang === 'FR'){
                messageCity = 'SVP choisir la ville!';
                requiredField = 'Tous les champs sont requis!';
            }
            if(document.getElementById('name').value === '' || document.getElementById('subject').value === '' || document.getElementById('message').value === ''){
                $('#retourTexte').html(requiredField);
                return false;
            }else{
                $('#retourTexte').html('');
            }
            if(loadedCity === ''){
                $('#retourTexte').html(messageCity);
                return false;
            }else{
                $('#retourTexte').html('');
            }

            // With all properties
            let body = {
                language: document.getElementById('language').value,
                name: document.getElementById('name').value,
                subject: document.getElementById('subject').value,
                message: document.getElementById('message').value,
                city: loadedCity,
            };
            axios
                .post(ThemeURL+"/send-contact", body)
                .then(function(response) {
                if(response.data.data.success === 'send'){
                    document.getElementById("button").innerHTML = "Thank you, we will contact you shortly!";
                    document.getElementById("button").disabled = true;
                }
                
                })
                .catch(function(error) {
                //console.log(error);
                });
        }else{
            return false;
        }
        
    }
    
    const retour = function (){
        return false;
    }
    return(
        
        <div id="form">
            <ReCAPTCHA
                sitekey="6LcZLEkiAAAAANzFQVfTjk_-uLbuGtJojxQ-qVE0"
                size="invisible"
                ref={captchaRef}
            />
            <h4 className={classes.titre}><span dangerouslySetInnerHTML={{__html: props.title}} ></span></h4>
            <form name="contact" id="contact" method='POST' onSubmit={retour}>
                <div className={classes.ranger} >
                    <div className={classes.col} >
                        <input type="hidden" name="language" id="language" value={props.lang} />
                        <input type="text" name="name" id="name" className={classes.input} placeholder={props.name} />
                    </div>
                    <div className={classes.col} >
                        <input type="text" name="subject" id="subject" className={classes.input} placeholder={props.subject} />
                    </div>
                    <div className={classes.full} >
                        <textarea name="message" id="message" className={classes.textarea} placeholder={props.message}></textarea>
                        <div className={classes.hidden} >
                        <input className="city" id="richmond" type="radio" name="city" value="Richmond, Qc" />
                        <input className="city" id="laval"  type="radio" name="city" value="Laval, Qc" />
                        </div>
                    </div>
                    <div className={classes.full} >
                        <button className={classes.button} type="button" id="button" onClick={submitForm}>{props.send}</button>
                        <span className={classes.retour} id="retourTexte"></span>
                    </div>
                </div>
                

            </form>
        </div>
    )
}

export default FormContact;