import classes from './LogoImage.module.css';

function LogoImage(props){

    var url = props.url
    var alt = props.alt;
    
    return(
        <img width="" className={classes.logo} src={url} alt={alt} />
    );
}

export default LogoImage;

