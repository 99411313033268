import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import LogoImage from "./LogoImage";
import LogoFacebook from './images/facebook-f.svg';
import LogolinkedIn from './images/linkedin-in.svg';
import LogoMail from './images/envelope.svg';
import classes from './Logo.module.css';
import Spinner from "../../pages/Spinner";
import GetUrl from "../../GetUrl";

function Logo(props){

  let currentLink = '/';
if(props.lang === 'EN'){
  currentLink = '/';
}else if(props.lang === 'FR'){
  currentLink = '/fr/';
}


const [isLoadingLogo, setIsLoadingLogo] = useState(true);
const [loadedLogo, setLoadedLogo] = useState([]);
var logourl;
useEffect(() => {
  const ThemeURL = GetUrl();
  setIsLoadingLogo(true);
  fetch(ThemeURL+"/wp-json/wp/v2/option")
    .then(function (response) {
      return response.json();
    })
    .then((data) => {
      
        var logoAttribute = [];
        logoAttribute.url = data.url;
        logoAttribute.alt = data.alt;
        setIsLoadingLogo(false);
        setLoadedLogo(logoAttribute);
      
      });

      
    
}, [logourl]);
if (isLoadingLogo) {
    return <Spinner />;
  }

  return (
    <div className={classes.ranger}>
      <Link to={currentLink} className={classes.anchor}>
        <LogoImage className='' url={loadedLogo.url} alt={loadedLogo.alt} />
        </Link>
        <div className={classes.logo_social}>
            <a target="_blank" rel="noreferrer" className={classes.a} href={props.facebook}><img src={LogoFacebook} className={classes.logo} alt="facebook" /></a> 
            <a target="_blank" rel="noreferrer" className={classes.a} href={props.linkedin}><img src={LogolinkedIn} className={classes.logo} alt="linkedin" /></a> 
            <a  className={classes.a} href={'mailto:'+props.mail}><img src={LogoMail} className={classes.logo} alt="mail" /></a>
          </div>
    </div>
  );
}
export default Logo;