import Video from "./Video"
import classes from './Videos.module.css';

function Videos(props){

    
    return (
        <div className={classes.container}>
            <div className={classes.ranger}>
                {props.content.map((contenu, index) => (
                
                    <Video key={index} className={classes.video} image={contenu.image} title={contenu.title} video={contenu.video} />
                
            ))}
            </div>
        </div>
    )
}
export default Videos