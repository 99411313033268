//import MainNavigation from './MainNavigation';
import InViewPort from '../../classes/InViewPort';



function Layout(props){
    return (
        <div>
            
            <InViewPort>
                <div>
                    {props.children} 
                </div>
            </InViewPort>
        </div>
    );
}

export default Layout;