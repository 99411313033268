import classes from './AccordeonLogoImage.module.css';
function AccordeonLogoImage(props){
    if(props.image.url){
        return(
            <div className={classes.colonne}>
                <img src={props.image.url} alt={props.image.alt} />
            </div>
        );
    }
    
}
export default AccordeonLogoImage;