import classes from './AvailablePosition.module.css';
import { Link } from 'react-router-dom';

function AvailablePosition(props, index){
    let liens;
    if(props.lang === 'FR'){
        liens = '/fr/carriere'+props.content.slug;
    }else{
        liens = '/career'+props.content.slug
    }
    return(
        <div className={classes.ranger}>
            <div className={classes.title_col}>
                <p className={classes.date}><span  dangerouslySetInnerHTML={{__html: props.content.date }} /></p>
                <h3 className={classes.titre}><span  dangerouslySetInnerHTML={{__html: props.content.title }} /></h3>
            </div>
            <div className={classes.location_col}>
                <p className={classes.location}><span  dangerouslySetInnerHTML={{__html: props.content.location }} /></p>
            </div>
            <div className={classes.time_col}>
                <p className={classes.time}><span  dangerouslySetInnerHTML={{__html: props.content.time }} /></p>
            </div>
            <div className={classes.link}>
                <Link className={classes.menu} key={index} to={liens}><button className={classes.button} dangerouslySetInnerHTML={{__html: props.button }} /></Link>
            </div>
        </div>
    )
}

export default AvailablePosition;