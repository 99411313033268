import classes from './AllTeamItem.module.css';
import LinkedinImage from './layout/images/linkedin-in-blue.svg';
import EnvelopeImage from './layout/images/envelope-blue.svg';

function AllTeamItem(props){
    const mailLink = 'mailto:'+props.mail;
    return(
        <div className={classes.col}>
            <div className={classes.img}>
                <img src={props.image.url} alt={props.image.alt} />
            </div>
            <div className={classes.info}>
                <p><span className={classes.name} dangerouslySetInnerHTML={{__html: props.name}} /> <span className={classes.job} dangerouslySetInnerHTML={{__html: props.job}} /></p>
                <p><span className={classes.phone} dangerouslySetInnerHTML={{__html: props.phone}} /></p>
                <p className={classes.link}><a rel="noreferrer" target="_blank" href={props.linkedin}><img className={classes.linkedin} src={LinkedinImage} alt="linkedin" /></a> <a rel="noreferrer" target="_blank" href={mailLink}><img className={classes.envelope} src={EnvelopeImage} alt="mail" /></a></p>
            </div>
        </div>
    )
}

export default AllTeamItem;