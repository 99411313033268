import classes from './BannerElement.module.css';
import { Link } from 'react-router-dom';

function BannerElement(props){
    var background = props.image;
    
    let lien;
    if(props.link.post_type !== 'page'){
        if(props.link.post_type !== 'career'){
            lien = props.link.post_type+'/'+props.link.post_name;
        }else{
            if(props.lang === 'FR'){
                lien = 'carriere/'+props.link.post_name;
            }else{
                lien = 'carreer/'+props.link.post_name;
            }
        }
        
    }else{
        lien = props.link.post_name;
    }
    return(
        <div className={classes.bgimage} style={{ backgroundImage: `url(${background})` }} >
            <div className={classes.abs} dangerouslySetInnerHTML={{__html: props.texte}} />
            <Link className={classes.readmore} key={props.link} to={lien} >Read more </Link>
        </div>
        
    )
}

export default BannerElement;