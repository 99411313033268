import { Link } from 'react-router-dom';
import classes from './FooterLoadedNav.module.css';

function FooterLoadedNav(props){

    //console.log(props.navs);

    return (
        <ul className={classes.ranger}>
            
            {props.navs.map((nav, index) => (
                    <li key={index}>
                        <Link className={classes.menu} key={nav.id} to={nav.slug}> {nav.title}</Link>
                    </li>
                ))}
            
            
        </ul>
        
    );
}

export default FooterLoadedNav;