import InViewPortClass from '../classes/InViewportClass';
import classes from './AllCertification.module.css';

function AllCertification(props){
    console.log(props.image_position);
    return(
        <div className={classes.ranger}>
            <div className={classes.col}>
            <InViewPortClass>
                <div dangerouslySetInnerHTML={{__html: props.text}}></div>
            </InViewPortClass>
            </div>
            <div className={classes.col_bg} style={{ backgroundImage: 'url('+props.image.url+')', backgroundPosition: props.image_position}} >
                <img src={props.image_hover.url} alt={props.image_hover.alt} style={{ objectPosition: props.image_hover_position}} />
            </div>
        </div>
    )
}
export default AllCertification;