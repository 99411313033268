import classes from './AboutSection.module.css';

function AboutSection(props){

    return(
        <div className={classes.texte_about_section}>
            <div className={classes.ranger}>
                <div className={classes.col} dangerouslySetInnerHTML={{__html: props.col_1}} />
                <div className={classes.col} dangerouslySetInnerHTML={{__html: props.col_2}} />
                <div className={classes.col} dangerouslySetInnerHTML={{__html: props.col_3}} />
            </div>
        </div>
    )
}
export default AboutSection;